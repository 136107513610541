<script>
import TagLabel from '../../components/TagLabel.vue'
import TableView from '../../components/TableView.vue'
import TopHeader from '../../components/TopHeader.vue'
import { UsersMixin } from '@/mixins/UsersMixin'
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin'

    export default {
        mixins: [UsersMixin, UtilitiesMixin],
        components: { TopHeader, TableView, TagLabel },

        mounted () {
            this.getAllSubscriptions()
        },

        data() {
            return {
                subscriptions: null,
                table: {headers: ["User", "Sub Id", "Plan", "Recurrence", "Source", "Medium", "Campaign", "Status", "Created", "Sign up"]},
                planColor: {pro: "#7C4DFF", solo: "#448AFF", business: "#8D6E63"}
            }
        },

        methods: {

            getAllSubscriptions() {
                this.getSubscriptions()
                .then(response => {
                    this.subscriptions = response
                })

            }            
        },
        
    }
</script>

<template>
    <div class="subscriptions-page">
        <TopHeader />

        <main class="container-fluid main-content mt64">

            <!-- Header -->
            <div class="page-header">
                <h1 class="page-title title-xl mb8">Assinaturas</h1>
            </div>

            <!-- Table -->
            <section v-if="subscriptions" class="subscriptions-table mt32">
                <TableView :headers="table.headers" >
                    <tr v-for="subscription in subscriptions" :key="subscription.id">
                        <td class="col"><router-link :to="{ name: 'UserForms', params: { user_id: subscription.user.id}}">{{subscription.user.email}}</router-link></td>
                        <td class="col">
                            <a v-if="subscription.gateway == 'stripe'" :href="`https://dashboard.stripe.com/subscriptions/${subscription.gateway_subscription_id}`">{{subscription.gateway}}</a>
                            <span v-else>{{subscription.gateway}}</span>
                        </td>
                        <td class="col"><TagLabel :color="planColor[subscription.plan_name]">{{subscription.plan_name}}</TagLabel></td>
                        <td class="col">{{subscription.recurrence}}</td>
                        <td class="col">{{subscription.user.utm_source}}</td>
                        <td class="col">{{subscription.user.utm_medium}}</td>
                        <td class="col">{{subscription.user.utm_campaign}}</td>
                        <td class="col">{{subscription.status}}</td>
                        <td class="col"><span v-tooltip="subscription.created_at">{{showTimeAgo(subscription.created_at)}}</span></td>
                        <td class="col" ><span v-tooltip="subscription.user.created_at">{{ getTimeBetween(subscription.created_at, subscription.user.created_at)}}</span></td>
                    </tr>
                </TableView>

                <Btn v-if="subscriptions.length >= 25" @click.native="loadMore" type="secondary" class="mt16 mb32">Carregar mais...</Btn>
            </section>


        </main>



    </div>
</template>


<style lang="sass">
.user-subscriptions-page

</style>